<template>
<div>
   <FinalForm
      class="h-full"
      ref="form"
    >
     <div class="h-full flex flex-col">
        <div class="form-row">
            <button
                class="main-topic button-color text-gray-500 focus:outline-none font-semibold py-2 px-4 border"
                :class="{ activeCatg: 'charge' === selectedCatg }"
                @click="selectedCatg = 'charge'">
                charge
            </button>
            <button
                class="customer button-color text-gray-500 focus:outline-none font-semibold py-2 px-4 border"
                :class="{ activeCatg: 'credit' === selectedCatg }"
                @click="selectedCatg = 'credit'">
                credit
            </button>
        </div>
        <div v-if="selectedCatg === 'charge'" >
            <div class="form-row">
                <div class="form-col">
                    <label for="categoryReport">charge code category</label>
                    <Dropdown
                        id="categoryReport"
                        :options="[{key: 1, value: '1'}, {key: 2, value: '2'}]"
                        multiple
                    />
                </div>
            </div>
             <div class="form-row">
                <div class="form-col">
                    <label for="categoryReport">charge code</label>
                    <Dropdown
                        id="categoryReport"
                        :options="[{key: 1, value: '1'}, {key: 2, value: '2'}]"
                        multiple
                    />
                </div>
            </div>
            <div class="form-row">
                <input type="checkbox"  id="prorate_charge" class="w-4 h-4 mt-1" />
                <label for="prorate_charge" class="ml-2 font-bold">prorate charge</label>
            </div>
            <div class="form-row">
                <div class="form-col">
                    <p for="categoryReport" class="text-sm mb-1">how often should this charge repeat?</p>
                    <Dropdown
                        id="chargeRepeat"
                        :options="chargeFrecuencyList"
                        v-model="chargeFrecuency"
                        @input="getChargeRepeatOn"
                        objectMode
                    />
                </div>
            </div>
            <div class="form-row">
                <div class="form-col">
                    <DatePicker
                      :label="'start date'"
                      :autoApply="false"
                    >
                    </DatePicker>
                </div>
                <div class="form-col">
                     <DatePicker
                      :label="'end date'"
                      :autoApply="false"
                    >
                    </DatePicker>
                </div>
            </div>
            <div class="form-row">
                <div class="form-col">
                    <DatePicker
                      :label="'charge repeats on'"
                      :value="chargeRepeatsOn"
                      :autoApply="false"
                    >
                    </DatePicker>
                </div>
                <div class="form-col">
                    <DatePicker
                      :label="'due date'"
                      :autoApply="false"
                    >
                    </DatePicker>
                </div>
            </div>
             <div class="form-row">
                <div class="form-col">
                    <button
                        class="flex items-center border-b font-bold focus:outline-none mb-4 button-color-link mt-10"
                        type="button"
                        >
                        override
                    </button>
                </div>
                <div class="form-col">
                  <AmountField
                      name="amount_charge"
                      label="amount"
                      :edit-mode="true"
                      :compute-width="false"
                      text-class="text-left"
                    />
                </div>
            </div>
            <div class="form-row">
                <div class="form-col">

                        <label >
                           description
                        </label>

                    <textarea
                    type="text"
                    class="form-input"
                    />
                </div>
            </div>
        </div>
        <div v-else>
           <div class="form-row">
                <div class="form-col">
                    <label for="categoryReport">charge code category</label>
                    <Dropdown
                        id="categoryReport"
                        :options="[{key: 1, value: '1'}, {key: 2, value: '2'}]"
                        multiple
                    />
                </div>
            </div>
             <div class="form-row">
                <div class="form-col">
                    <label for="categoryReport">charge code</label>
                    <Dropdown
                        id="categoryReport"
                        :options="[{key: 1, value: '1'}, {key: 2, value: '2'}]"
                        multiple
                    />
                </div>
            </div>
            <div class="form-row">
                <input type="checkbox"  id="prorate_charge" class="w-4 h-4 mt-1" />
                <label for="prorate_charge" class="ml-2 font-bold">prorate charge</label>
            </div>
            <div class="form-row">
                <div class="form-col">
                    <p for="categoryReport" class="text-sm mb-1">how often should this charge repeat?</p>
                    <Dropdown
                        id="chargeRepeat"
                        :options="chargeFrecuencyList"
                        v-model="chargeFrecuency"
                        @input="getChargeRepeatOn"
                        objectMode
                    />
                </div>
            </div>
            <div class="form-row">
                <div class="form-col">
                    <DatePicker
                      :label="'start date'"
                      :autoApply="false"
                    >
                    </DatePicker>
                </div>
                <div class="form-col">
                    <DatePicker
                      :label="'end date'"
                      :autoApply="false"
                    >
                    </DatePicker>
                </div>
            </div>
            <div class="form-row">
                <div class="form-col">
                  <DatePicker
                      :label="'charge repeats on'"
                      :value="creditRepeatsOn"
                      :autoApply="false"
                    >
                    </DatePicker>
                </div>
                <div class="form-col">
                  <DatePicker
                      :label="'due date'"
                      :autoApply="false"
                    >
                    </DatePicker>
                </div>
            </div>
             <div class="form-row">
                <div class="form-col">
                    <button
                        class="flex items-center border-b font-bold focus:outline-none mb-4 button-color-link mt-10"
                        type="button"
                        >
                        override
                    </button>
                </div>
                <div class="form-col">
                  <AmountField
                      name="amount_credit"
                      label="amount"

                      :edit-mode="true"
                      :compute-width="false"
                      text-class="text-left"
                    />
                </div>
            </div>
            <div class="form-row">
                <div class="form-col">

                        <label >
                           description
                        </label>

                    <textarea
                    type="text"
                    class="form-input"
                    />
                </div>
            </div>
        </div>
    </div>
    </FinalForm>
</div>

</template>

<script>
import {FinalForm} from 'vue-final-form';
import ModalNavigation from '@/mixins/ModalNavigation'
import NotifyMixin from '@/mixins/NotifyMixin'
import AmountField from "@/components/form/AmountField";
import DatePicker from '@/components/move_in/DatePicker';
import Dropdown from '@/components/ui/Dropdown';

export default {
    components: { Dropdown, DatePicker, AmountField, FinalForm },
    mixins: [ ModalNavigation, NotifyMixin ],
    data() {
        return {
            selectedCatg: 'charge',
            chargeFrecuency: null,
            chargeFrecuencyList: [
              {
                  key:1,
                  value: 'monthly'
              }
            ],
            chargeRepeat: null,
            chargeRepeatList: [],
            chargeRepeatsOn: '',
            creditRepeatsOn: '',
        }
    },
    methods: {
        getChargeRepeatOn(data){
            switch (data.key) {
                case 1:
                    this.chargeRepeatList = [
                        {key:1, value:1},
                        {key:2, value:2},
                        {key:3, value:3},
                    ]
                    break;
                case 2:
                    this.chargeRepeatList = [
                        {key:1, value:1},
                        {key:2, value:2},
                        {key:3, value:3},
                        {key:4, value:4},
                    ]
                    break;
                case 3:
                    this.chargeRepeatList = [
                        {key:1, value:1},
                        {key:2, value:2},
                        {key:3, value:3},
                        {key:4, value:4},
                        {key:5, value:5},
                    ]
                    break;
                default:
                    break;
            }

        },
        getFirstDayOfTheMonth(){
          const date = new Date();
          return new Date(date.getFullYear(), date.getMonth(), 1);
        }
    },
    created(){
      this.chargeRepeatsOn = this.getFirstDayOfTheMonth()
      this.creditRepeatsOn = this.getFirstDayOfTheMonth()
    }
}
</script>

<style scoped>
.color-text{
    color: var(--highlightColor500);
}

.button-color-link{
    border-color: var(--highlightColor500);
    color: var(--highlightColor500);
}

.button-color{
  border-color: var(--highlightColor500);
  background-color: var(--highlightColor300);
}
  .form {
    width: 55%;
  }
  ul:before {
    content: attr(aria-label);
    font-size: 1rem;
    font-weight: bold;
    color: black;
  }
  ul > li {
    @apply py-3 font-light text-black;
  }
  .activeCatg {
    background-color: var(--highlightColor500);
    @apply text-white border-transparent;
  }
</style>
